<template>
  <base-section
    id="it-solutions"
    dark
  >
    <base-section-heading
      icon="mdi-monitor"
      title="IT 솔루션"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionItSolutions',

    data: () => ({
      cards: [
        {
          title: 'ERP',
          subtitle: 'Enterprise Resource Planning',
          text: 'ERP는 기업의 재무, 영업, 생산, 인사, 공급망 등 모든 자원을 하나의 시스템으로 통합 관리하는 시스템입니다.',
        },
        {
          title: 'WMS',
          subtitle: 'Warehouse Management System',
          text: 'WMS는 창고 내 입출고, 재고 관리, 위치 관리 등을 자동화하는 창고 관리 시스템입니다.',
        },
        {
          title: 'WCS',
          subtitle: 'Warehouse Control System',
          text: 'WCS는 WMS와 연동하여 창고 내 입출고 작업을 실시간으로 제어하고 관리하는 창고 제어 시스템입니다.',
        },
        {
          title: 'MES',
          subtitle: 'Manufacturing Execution System',
          text: 'MES는 생산 현장의 실시간 데이터를 수집하여 생산 계획, 작업 지시, 품질 관리, 설비 관리 등을 통합 관리하는 생산 실행 시스템입니다.',
        },
      ],
    }),
  }
</script>
